$PRIMARY: rgba(224, 29, 64, 1);

$SECONDARY: #ffeded;

$WHITE: rgba(255, 255, 255, 0.5);

$white: $WHITE;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$card-border-width: 1px;
// $card-border-color: var(--#{$prefix}border-color-translucent);
$card-border-radius: 1rem;

$card-box-shadow: null;

// scss-docs-start theme-color-variables
$primary: $PRIMARY;
$secondary: $SECONDARY;

// scss-docs-end theme-color-variables

@import "/node_modules/bootstrap/scss/bootstrap.scss";
