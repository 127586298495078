.homeContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-text-size {
  font-size: 12px;
}
.box {
  border-radius: 1rem;
}

.chargingStations {
  
  .contentBox {
    // min-height: 25vh;
  }
}
